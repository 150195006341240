<template>
  <div class="input-group px-2 py-3" :id="'browse-' + name">
    <label class="input-group-btn">
        <span class="btn btn-outline-primary">
            <span class="buttonText">{{buttonText}}&hellip;</span>
            <input type="file" style="display: none;" @change="changeBrowse" :id="name" :accept="accept" :state="state">
        </span>
    </label>
    <input type="text" id="myFile" class="form-control ml-3" :value="selectedFile" readonly>
  </div>
</template>

<script>
export default {
  name: "BrowseButton",
  data: function() {
    return {
      selectedFile: this.noFile
    }
  },
  props: {
    buttonText: String,
    accept: String,
    name: String,
    noFile: String,
    state: Boolean
  },
  methods: {
    changeBrowse: function(e) {
      const label = e.target.value.replace(/\\/g, '/').replace(/.*\//, '');
      if (label.length === 0) {
        this.selectedFile = this.noFile
      } else {
        this.selectedFile = label
      }
      
      // console.log("Emitujem event fileSelected");
      this.$emit("fileSelected");
    }
  }
}
</script>

<style scoped>
  .input-group {background-color: #fff; border: 2px solid #D9DBE1; border-radius: 8px;}
  .buttonText {font-weight: 500;}
  #myFile {background-color: #fff; border: 0; margin-top: .3rem; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
  .input-group-btn {margin: 0;}
  .is-invalid {border-color: #dc3545;}
</style>
