<template>
	<div>
		<b-form @submit.prevent="onSubmit" autocomplete="off">
			<!-- Naslov je dosta specifičan. Ako je u pitanju latinično pismo na srpskom, onda se pored naslova -->
			<!-- trebalo da se pojavi ikonica za LAT tag. -->
			<b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :label="$t('videos.title')" label-for="title">
				<template v-if="$root.lang.IANA === 'sr-Latn' || $root.lang.IANA === 'sr-Cyrl'">
					<b-row>
						<b-col md="11">
							<b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('videos.enter-title')" autocomplete="new-password" trim></b-form-input>
							<b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
						</b-col>
						<b-col md="1" class="col-form-label">
							<span id="latIcon" @click="insertLat('title')">LAT</span>
						</b-col>
					</b-row>
				</template>
				<template v-else>
					<b-row>
						<b-col md="12">
							<b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('videos.enter-title')" autocomplete="new-password" trim></b-form-input>
							<b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
						</b-col>
					</b-row>
				</template>
			</b-form-group>
			<b-form-group	label-cols-sm="12"	label-cols-md="3" label-cols-lg="4"	:label="$t('videos.file')"	label-for="mediaFile">
				<BrowseButton
					:state="mediaFileState"
					name="mediaFile"
					:buttonText="$t('videos.choose-video')"
					:noFile="$t('videos.no-video-file-selected')"
				/>
				<b-form-invalid-feedback>{{ $t("videos.select-valid-video-file") }}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group label-cols-sm="12" label-cols-md="3" label-cols-lg="4" :state="acceptedTermsState">
				<b-form-checkbox v-model="acceptedTerms" value="accepted" unchecked-value="not_accepted">
					<i18n path="general.accept-terms1" tag="p">
						<a :href="$t('general.termsLink2')" target="_blank">
							{{ $t("general.accept-terms2") }}
						</a>
					</i18n>
				</b-form-checkbox>
				<b-form-invalid-feedback :state="acceptedTermsState">{{ $t("general.must-accept-terms")	}}</b-form-invalid-feedback>
			</b-form-group>

			<b-form-group label-cols-sm="12" label-cols-md="4">
				<p>{{ $t("videos.upload-description") }}</p>
				<i18n path="general.problem" tag="p">
					<a href="mailto:dmn@rtv.rs" target="_blank" rel="noopener">dmn@rtv.rs</a>
				</i18n>
			</b-form-group>

			<b-form-group label-cols-sm="12" label-cols-md="4">
				<b-button	:click="check"	type="submit"	ref="submit" :disabled="busy" variant="primary">{{ $t("videos.add-video") }}</b-button>
				<div id="uploadInfo"></div>
			</b-form-group>
		</b-form>

		<b-overlay :show="busy" no-wrap @shown="confirmDialog" @hidden="onHidden">
			<template #overlay>
				<div class="row">
					<b-col cols="12" v-if="processing" class="text-center bg-white" style="width:400px; height: 300px; border: 2px solid #9ADCDA; border-radius: 8px;">
						<div class="p-4 mt-5">
							<BIconCloudArrowUpFill font-scale="3" variant="primary" />
							<div class="mb-3">{{ $t("general.uploading") }}</div>
							<b-progress min="0" max="100" variant="primary" height="30px" class="mx-2 rounded-0">
								<b-progress-bar :value="uploadProgress">{{ uploadProgress.toFixed(2) }} %</b-progress-bar>
							</b-progress>
						</div>
					</b-col>
					<b-col cols="12" v-else ref="dialog"	tabindex="-1"	role="dialog"	aria-modal="false"	aria-labelledby="form-confirm-label"	class="bg-white align-self-center"	style="width:400px; height: 300px; border: 2px solid #9ADCDA; border-radius: 8px; text-align: center;">
						<div class="p-4 mt-5">
							<div class="mb-3">
								<strong id="form-confirm-label">{{
									$t("general.are-you-sure")
								}}</strong>
							</div>
							<div>
								<b-button	variant="outline-primary"	class="mr-3"	@click="onCancel">{{ $t("general.no") }}</b-button>
								<b-button variant="primary" @click="onOK">{{$t("general.yes")}}</b-button>
							</div>
						</div>
					</b-col>
				</div>
			</template>
		</b-overlay>
	</div>
</template>

<script>
import {BProgress, BIconCloudArrowUpFill, BFormCheckbox,	BProgressBar} from "bootstrap-vue";
import BrowseButton from "@/components/util/BrowseButton.vue";
export default {
	name: "VideoForm",
	components: {
		BProgress,
		BProgressBar,
		BIconCloudArrowUpFill,
		BFormCheckbox,
		BrowseButton
	},
	props: {
		media: Object
	},

	data: function() {
		return {
			mediaFile: null,
			mediaFileData: null,
			processing: false,
			uploading: false,
			busy: false,
			uploadProgress: 0,
			acceptedTerms: "not_accepted",
			titleState: null,
			mediaFileState: null,
			acceptedTermsState: null,
			selectedTitleTextForLat: null
		};
	},

	created: function() {},

	methods: {
		onSubmit: function(e) {
			if (this.validateForm()) {
				this.busy = true;
				//console.log(this.processing)
				if (this.processing) {
					this.media.createdBy = this.getAuthData().email;
					const data = new FormData();

					data.append("mediaData", JSON.stringify(this.media));
					data.append("fullRes", document.getElementById("mediaFile").files[0]);
					const axiosConfig = {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: this.getAuthData().accessToken
						},
						onUploadProgress: function(progressEvent) {
							this.uploadProgress =
								(progressEvent.loaded * 100) / progressEvent.total;
						}.bind(this)
					};

					this.$http
						.post("cms/api/media/save", data, axiosConfig)
						.then(r => {
							if (r.data) {
								this.processing = false;
								this.busy = false;
								// console.log(r.data.mimeType);
								this.$router.push({
									name: "ProcessingVideo",
									params: { videoId: r.data.id }
								});
							}
						})
						.catch(e => {
							this.processing = false;
							this.busy = false;
							// console.log(e);
							alert("Došlo je do prekida konekcije.");
						});
					this.saveSpinner = false;
				}
			}
		},

		check: function() {
			//console.log("Kliknuto na dugme upload!");
		},

		confirmDialog: function() {
			this.$refs.dialog.focus();
		},

		onHidden: function() {
			this.$refs.submit.focus();
		},

		onOK: function() {
			this.processing = true;
			this.onSubmit();
		},

		onCancel: function() {
			this.processing = false;
			this.busy = false;
		},

		validateForm: function() {
			if (typeof this.media.title === "undefined" ||	this.media.title.length === 0) {
				this.titleState = false;
				return false;
			} else {
				this.titleState = null;
			}

			if (typeof document.getElementById("mediaFile").files[0] === "undefined") {
				document.getElementById("browse-mediaFile").classList.add("is-invalid");
				this.mediaFileState = false;
				return false;
			} else {
				document.getElementById("browse-mediaFile").classList.remove("is-invalid");
				this.mediaFileState = null;
			}

			if (this.acceptedTerms === "not_accepted") {
				this.acceptedTermsState = false;
				return false;
			} else {
				this.acceptedTermsState = null;
			}
			return true;
		},

		beforeRouteLeave(to, from, next) {
			const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
			if (answer) {
				next();
			} else {
				next(false);
			}
		}

	}
};
</script>
<style></style>
