<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('videos.add-video') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <VideoForm :media="{}"/>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import VideoForm from '@/components/admin/video/VideoForm.vue'
export default {
  name: "AddVideo",
  components : { NavBar, VideoForm }
}
</script>
<style></style>
